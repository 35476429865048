var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useLayoutEffect, useRef } from "react";
import Page from "../components/Page";
import Hero from "../components/Hero";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Base } from "../styles";
import CalloutGrid from "../components/CalloutGrid";
import SeriesCTA from "../components/SeriesCTA";
import Footer from "../components/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
export default (props) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: {eq: "genuine-parts/Genuine_Parts_Header.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1080
            aspectRatio: 1
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }

      ctaimage: file(relativePath: {eq: "pre-footer.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 2
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }

      cardGridImageOne: file(
        relativePath: {eq: "genuine-parts/Kawasaki_KTECH_Oils.jpg"}
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            aspectRatio: 1
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
      cardGridImageTwo: file(
        relativePath: {eq: "genuine-parts/Kawasaki_KTECH_Fuels_1.jpg"}
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            aspectRatio: 1
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
      cardGridImageThree: file(
        relativePath: {eq: "genuine-parts/Genuine_Parts.jpg"}
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            aspectRatio: 1
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
    }
  `);
  const heroImage = getImage(data.hero);
  const seriesCTABackgroundImage = getImage(data.ctaimage);
  const gridOne = getImage(data.cardGridImageOne);
  const gridTwo = getImage(data.cardGridImageTwo);
  const gridThree = getImage(data.cardGridImageThree);
  const tl = useRef(null);
  const container = useRef(null);
  const heroRef = useRef(null);
  const calloutGridRef = useRef(null);
  useLayoutEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.from([
      heroRef.current.headlines[0].current,
      heroRef.current.headlines[1].current,
      heroRef.current.headlines[2].current
    ], {
      duration: 1,
      opacity: 0,
      stagger: 0.5,
      ease: "sine.out"
    });
    tl.current.from(heroRef.current.img.current, {
      duration: 2,
      scale: 1.5,
      opacity: 0,
      ease: "sine.out"
    }, "-=2");
    tl.current.from(heroRef.current.fullWidthTxt.current, {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "sine.out",
      scrollTrigger: {
        trigger: heroRef.current.fullWidthTxt.current,
        start: "bottom-=100 bottom",
        end: "+=100",
        scrub: 1
      }
    });
    calloutGridRef.current.cardRefArr.forEach((card, index) => {
      tl.current.from([card.headline.current, card.line.current, card.copy.current], {
        duration: 0.5,
        x: index % 2 === 0 ? -100 : 100,
        opacity: 0,
        stagger: 0.15,
        ease: "sine.out",
        scrollTrigger: {
          trigger: card.img.current,
          start: "top+=150 bottom",
          end: "+=300",
          scrub: 1
        }
      });
      tl.current.from(card.line.current, {
        duration: 0.8,
        width: 300,
        ease: "sine.out",
        scrollTrigger: {
          trigger: card.img.current,
          start: "top+=150 bottom",
          end: "+=300",
          scrub: 1
        }
      }, "-=0.3");
      tl.current.from(card.img.current, {
        duration: 0.8,
        ease: "sine.out",
        x: index % 2 === 0 ? 100 : -100,
        opacity: 0,
        scrollTrigger: {
          trigger: card.img.current,
          start: "top+=150 bottom",
          end: "+=300",
          scrub: 1
        }
      }, "-=0.3");
    });
  }, [container, heroRef, calloutGridRef]);
  return /* @__PURE__ */ React.createElement(Page, __spreadProps(__spreadValues({}, props), {
    title: "Trust Our Genuine Parts | Kawasaki Genuine Parts",
    metaDescription: "Our oils, filters, parts, and tune-up kits are formulated to help you get years of power and performance out of your engine."
  }), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    display: "flex",
    flexDirection: "column",
    ref: container
  }, /* @__PURE__ */ React.createElement(Hero, {
    headline: ["Protect", "your", "investment."],
    copy: "Give your engine the life it was engineered to have by maintaining it with our line of genuine parts. Our oils, filters, parts, and tune-up kits have all been uniquely formulated and designed to help you get years of power and performance out of your engine.",
    image: heroImage,
    imageAlt: "Hand pouring Kawasaki 4-Cycle Engine Oil into funnel.",
    ref: heroRef
  }), /* @__PURE__ */ React.createElement(CalloutGrid, {
    ref: calloutGridRef,
    cards: [
      {
        headline: 'Kawasaki KTECH<span style="font-size:.5em;vertical-align: super;">\u2122</span> Oils',
        copy: "Not all oils are created the same. In fact, using the wrong oil in commercial-grade equipment could lead to costly repairs or even a total replacement. Kawasaki KTECH oil is made specially for mower engines and other landscaping equipment\u2014engines that run hard and hot through every summer schedule and marathon workday. It\u2019s oil engineered to the same strict standards as Kawasaki engines.",
        image: gridOne
      },
      {
        headline: "Kawasaki KTECH Fuels",
        copy: "From reduced maintenance downtime and long-term storage to improved throttle response and more dependable starts, Kawasaki KTECH fuels are engineered to do what strictly ethanol gasolines can\u2019t\u2014safeguard against moisture, oxidization, phase separation, and gummy deposits. Plus, they\u2019re specially developed for use in both carburetor and fuel-injection systems.",
        image: gridTwo
      },
      {
        headline: "Genuine Parts",
        copy: "An engine is only as good as its maintenance. From tune-up kits and filters for regular maintenance to genuine replacement parts for a longer life, our genuine parts are designed to keep your engine running at its best and keep you working.",
        image: gridThree
      }
    ]
  }), /* @__PURE__ */ React.createElement(SeriesCTA, {
    headline: "Made to Work",
    copy: "Learn how each of our engines is designed and built to tackle the toughest jobs out there.",
    link: {
      url: "/engines/",
      text: "Explore Engines"
    },
    copySide: "left",
    backgroundImage: seriesCTABackgroundImage
  })), /* @__PURE__ */ React.createElement(Footer, null));
};
